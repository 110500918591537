.home {
  margin-top: 2rem;
  @media (min-width: 576px) {
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 9rem);
    margin-bottom: 0;
    justify-content: center;
  }
}

.home-title {
  font-size: var(--font-size-sm);
  font-weight: var(--font-weight-strong);
  margin-bottom: 0.5rem;

  @media (min-width: 576px) {
    font-size: var(--font-size-md);
  }
}

.home-content {
  text-align: center;
  max-width: 50rem;
  margin: auto;
}

.home-description {
  font-size: var(--font-size-sm);
}

.home-logo {
  align-self: flex-start;
  max-width: 100%;
  margin: 0 auto 2rem;

  @media (min-width: 576px) {
    margin-bottom: 2rem;
    max-width: 88%;
  }
}
