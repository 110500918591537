.about {
  display: flex;
  flex-direction: column;
}

.about-section-list {
  display: inline-flex;
  grid-gap: 2rem;
  flex-wrap: wrap;
  width: 100%;
  @media (min-width: 768px) {
    margin-top: 9rem;
  }
}

.about-list-item {
  background-color: rgba(88, 62, 64, 0.03);
  padding: 1.6rem;
  border-radius: 1.6rem;
  box-sizing: border-box;

  @media (min-width: 576px) {
    padding: 2.8rem;
  }

  @media (min-width: 768px) {
    min-height: auto;
    width: 100%;
  }

  @media (min-width: 992px) {
    width: 47%;
  }
}
